<template>
  <div v-if="loading.proof" class="ui center aligned text container">
    <i class="loading spinner icon"/>
  </div>
  <template v-else>
    <div v-if="!error" class="body">
      <template v-if="proof.approved">
        <img
          style="width: 15em;"
          v-if="proof.template === TEMPLATE_BRANDED"
          src="@/assets/is_approval_received.svg"
        />
        <h1 v-else>
          Your approval has been received.
        </h1>
      </template>
      <template v-else>
        <img
          style="max-width: 25em;"
          v-if="proof.template === TEMPLATE_BRANDED"
          src="@/assets/is_approval_banner.svg"
        />
        <h1 v-else>
          We need your approval.
        </h1>
      </template>
      
      <iframe
        v-if="proof"
        :src="`https://drive.google.com/viewerng/viewer?url=${proof.file}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`"
      />
      <h3 v-if="proof?.approved">
        Thank you. Your approval has been received.
      </h3>
      <template v-else-if="proof?.approved === false">
        <h3>
          You rejected the proof and it is currently being reworked. <br />
          Reason: {{ proof.rejection_reason }}
        </h3>
      </template>
      <template v-else>
        <h3>
          Your proof is finished and ready to move to production.<br />
          Please review and approve it to continue.
        </h3>
        <a :href="proof?.file" target="_blank">
          Trouble visualizing? Click here to download
        </a>
        <div id="proofForm" class="ui form">
          <div class="ui error message"></div>
        </div>
        <div class="separate">
          <button
            class="ui small green button"
            :class="{ loading: loading.approve }"
            :disabled="loading.approve || loading.reject"
            @click="onApproveClick"
          >
            Looks good, approve
          </button>
          <button
            class="ui small red basic button"
            :class="{ loading: loading.reject }"
            :disabled="loading.approve || loading.reject"
            @click="onRejectClick"
          >
            Reject
          </button>
        </div>
      </template>
      <div v-if="proof.template === TEMPLATE_BRANDED" class="ui stackable grid">
        <div class="eight column centered row">
          <div class="column" style="text-align: center;">
            <img style="width: 6em;" src="@/assets/is_logo.svg" />
          </div>
          <div class="column" style="text-align: center;">
            <img style="width: 9em;" src="@/assets/is_services.svg" />
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center;" v-else-if="error === 404">
      <div class="column" style="text-align: center;">
        <img style="width: 12em;" src="@/assets/is_logo.svg" />
      </div>
      <h1>Something went wrong.</h1>
      <h3>The requested proof was not found.</h3>
    </div>
  </template>
</template>

<script>
import formErrorHandler from "@/utils/formErrorHandler.js";

export default {
  data() {
    return {
      rejection_reason: null,
      proof: null,

      error: null,
      loading: {
        proof: true,
        approve: false,
        reject: false
      },

      TEMPLATE_BLIND: "BL",
      TEMPLATE_BRANDED: "BR"
    };
  },

  methods: {
    onApproveClick() {
      this.loading.approve = true;
      this.$http
        .patch(`api/v1/proofs/${this.$route.params.id}/approve/`)
        .then(() => {
          location.reload();
        })
        .catch(error => {
          formErrorHandler("proofForm", error);
          this.loading.approve = false;
        });
    },

    onRejectClick() {
      let rejection_reason = window.prompt(
        "Please input the reason for the rejection."
      );
      if (rejection_reason !== null) {
        this.loading.reject = true;
        this.$http
          .patch(`api/v1/proofs/${this.$route.params.id}/reject/`, {
            rejection_reason
          })
          .then(() => {
            location.reload();
          })
          .catch(error => {
            formErrorHandler("proofForm", error);
            this.loading.reject = false;
          });
      }
    }
  },

  async created() {
    await this.$http
      .get(`api/v1/proofs/${this.$route.params.id}/`)
      .then(response => {
        this.loading.proof = false;
        this.proof = response.data;
        this.proof.file = this.proof.file.replace(
          "http://localhost:8000",
          "http://localhost:8002"
        );
        this.proof.file = this.proof.file.replace(
          "http://api.om",
          "https://media.om"
        );
      })
      .catch(error => {
        this.loading.proof = false;
        if (error.response.status) {
          this.error = 404;
        }
      });

    if (this.proof.template === this.TEMPLATE_BLIND) {
      document.title = "Proof approval page";
    }
  }
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100vh;
  text-align: center;
}

iframe {
  width: 100%;
  height: calc(100vh - 16em);
}

h3 {
  font-size: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

a {
  font-size: 80%;
}

.separate {
  margin-top: 1em;
  margin-bottom: 1em;
}

.separate *:first-child {
  margin-right: 4em;
}
</style>
